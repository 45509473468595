import { request } from '@/util/request'

export function getList(params, data) {
  return request({
    url:
      '/api/HrSatff/GetNpcList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize,
    method: 'post',
    data: data,
  })
}

export function doAdd(data) {
  return request({
    url: '/api/HrSatff/AddNPC',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/api/HrSatff/UpdateNPC',
    method: 'put',
    data,
  })
}

export function doChange(data) {
  return request({
    url: '/api/HrSatff/ChangeNpc',
    method: 'put',
    data,
  })
}

export function doDelete(params) {
  return request({
    url: '/api/HrSatff/DeleteNpc',
    method: 'delete',
    params,
  })
}

export function getDLGOptions(data) {
  return request({
    url: '/api/HrSatff/GetOptions',
    method: 'post',
    data,
  })
}

export function GetExcelData(data) {
  return request({
    url: '/api/HrSatff/GetExcelData',
    method: 'post',
    data,
  })
}
