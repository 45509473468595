<template>
  <div style="margin: 14px">
    <van-form @submit="onSubmit">
      <div style="text-align: center;background-color: #ebebeb;color: #646566;height: 60px;line-height: 60px">
        <span style="font-size: 24px;margin-top: 5px"><strong>个人中心</strong></span>
      </div>
      <van-field
        v-model="formData.Staff_Name"
        name="姓名"
        label="姓名"
        readonly
      />
      <van-field
        v-model="formData.Case_ID"
        name="账号"
        label="账号"
        readonly
      />
      <van-field
        v-model="formData.Meet_Name"
        name="届次"
        label="届次"
        readonly
      />
      <van-field
        v-model="formData.DLG_Name"
        name="提案提交单位"
        label="提案提交单位"
        readonly
      />
      <van-field
        v-model="formData.BackGround"
        name="政治面貌"
        label="政治面貌"
      />
      <van-field
        v-model="formData.Address"
        name="单位职务"
        label="单位职务"
      />
      <van-field
        v-model="formData.Mobile"
        name="联系电话"
        label="联系电话"
      />
      <van-field
        v-model="formData.Native_place"
        name="联系地址"
        label="联系地址"
      />
      <van-field
        v-model="formData.Remark"
        name="单位地址"
        label="单位地址"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import store from "@/store";
  import {Toast} from "vant";
  import { GetUserInfoByID } from '@/api/userManagement'
  import { doEdit } from '@/api/npcManagement'
  import { Log } from '@/api/logManagement'
  import {Emas} from "@/assets/js/Emas";

  export default {
    name: "index",
    data() {
      return {
        flag: true,
        formData: {
          ID: 0,
          Staff_Name: '',
          Case_ID: '',
          SPV_ID: null,
          DLG_ID: null,
          BackGround: '',
          Address: '',
          Create_Time: null,
          Operator: 0,
          Type: 2,
          Mobile: '',
          Meet_Name: '',
          DLG_Name: '',
        },
      }
    },
    created() {
      this.flag = this.$route.query.flag === '1'
      this.fetchData()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/person", "个人首页", "zzdzx.yy.gov.cn/person")
    },
    methods: {
      async fetchData() {
        let userInfo = store.getters['user/userInfo']
        let User_ID = userInfo.user_ID
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载...',
          forbidClick: true,
        });
        const { data } = await GetUserInfoByID({ User_ID: User_ID })
        Toast.clear()
        this.formData = {
          ID: data.hR_ID,
          Staff_Name: data.staff_Name,
          Case_ID: data.case_ID,
          SPV_ID: data.spV_ID,
          DLG_ID: data.dlG_ID,
          BackGround: data.backGround,
          Address: data.address,
          Create_Time: data.create_Time,
          Operator: data.operator,
          Type: data.type,
          Mobile: data.mobile,
          Native_place: data.native_place,
          Remark: data.remark,
          Meet_Name: data.meet_Name,
          DLG_Name: data.dlG_Name,
        }
      },

      async onSubmit() {
        let userInfo = store.getters['user/userInfo']
        this.formData.Operator = userInfo.user_ID
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在保存...',
          forbidClick: true,
        });
        const { msg } = await doEdit(this.formData)
        if(!this.flag) {
          await this.saveLog()
        }
        Toast.clear();
        Toast.success(msg);
        await this.$router.push('/index')
      },


      async saveLog() {
        let userInfo = store.getters['user/userInfo']
        let obj = {
          Module: 'Person',
          User_ID: userInfo.user_ID,
          Description: userInfo.staff_Name + '确认',
        }
        await Log(obj)
      }

    }
  }
</script>

<style scoped>

</style>